import React, { useState } from "react"
import axios from "axios"
import Layout from "../components/layout"
import useSiteMetadata from "../components/use-site-metadata"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Helmet } from "react-helmet"

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"

const Contact = ({ data, location }) => {
  const [token, setToken] = useState()

  const [, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    const success = () =>
      toast.success("Your message was sent. Thank you for your feedback.")
    const failure = () => toast.error("Something went wrong.")

    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    const data = new FormData(form)
    data.append("g-recaptcha-response", token)

    axios({
      method: "post",
      url: "https://getform.io/f/88d3be2e-ad13-421f-8748-fe8885787e51",
      data,
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
        success()
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
        failure()
        throw new Error(r)
      })
  }

  const { domain } = useSiteMetadata()

  return (
    <Layout locationHref="/contact">
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/contact.css" />
      </Helmet>
      <GoogleReCaptchaProvider reCaptchaKey="6LfJDdEaAAAAAB9cR2R36-CFKX5IHn4iZ4ulLxkd">
        <div class="form-style-2">
          <form onSubmit={handleOnSubmit}>
            <label for="name">
              <span>Name</span>
              <input type="text" name="name" />
            </label>
            <label for="eail">
              <span>Email</span>
              <input type="email" name="email" />
            </label>
            <label for="message">
              <span>Message</span>
              <textarea name="message"></textarea>
            </label>
            <input type="hidden" name="domain" value={domain} />
            <div className="form-group">
              <button type="submit">Send</button>
              <ToastContainer position="top-center" />
              <GoogleReCaptcha
                onVerify={token => {
                  setToken(token)
                }}
              />
            </div>
          </form>
        </div>
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default Contact
